
import { defineComponent, ref, PropType } from "vue";
import { FAQitem } from "../../types";

export default defineComponent({
  setup() {
    const activeKey = ref([0]);

    return {
      activeKey,
    };
  },
  props: {
    items: {
      type: Object as PropType<FAQitem[]>,
      required: true,
    },
  },
});
