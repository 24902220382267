import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-337c8250"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = { class: "position-relative main-header" }
const _hoisted_3 = { class: "container container-characteristics" }
const _hoisted_4 = { class: "casino-box1 container-casinos position-relative overflow-hidden" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "container aboveLvl" }
const _hoisted_7 = { class: "container container-slots" }
const _hoisted_8 = { class: "container container-slots" }
const _hoisted_9 = { class: "container container-slots" }
const _hoisted_10 = {
  key: 1,
  class: "fluid-container hide-on-mobile"
}
const _hoisted_11 = {
  key: 2,
  class: "fluid-container hide-on-mobile"
}
const _hoisted_12 = { class: "container-bonuses overflow-hidden" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "container aboveLvl" }
const _hoisted_15 = { class: "container" }
const _hoisted_16 = {
  key: 3,
  class: "container container-partners"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_SectionHomeCharacteristics = _resolveComponent("SectionHomeCharacteristics")!
  const _component_SectionHomeCasino = _resolveComponent("SectionHomeCasino")!
  const _component_SectionPachinkoCasino = _resolveComponent("SectionPachinkoCasino")!
  const _component_SectionHomeGameType = _resolveComponent("SectionHomeGameType")!
  const _component_BitcoinCards = _resolveComponent("BitcoinCards")!
  const _component_Jumbotron = _resolveComponent("Jumbotron")!
  const _component_SectionWithImage = _resolveComponent("SectionWithImage")!
  const _component_FAQ = _resolveComponent("FAQ")!
  const _component_SectionHomeBonuses = _resolveComponent("SectionHomeBonuses")!
  const _component_SectionHomeInfo = _resolveComponent("SectionHomeInfo")!
  const _component_carousel_partners = _resolveComponent("carousel-partners")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, {
      loading: _ctx.loading && _ctx.sectionLoading && _ctx.loadingFaq
    }, null, 8, ["loading"]),
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, null, {
          default: _withCtx(() => [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_main_header, {
                      class: "my-auto",
                      title: _ctx.header.title,
                      subtitle: _ctx.header.subtitle,
                      description: _ctx.header.description,
                      secondaryText: _ctx.header.email,
                      href: _ctx.header.button.href,
                      buttonName: _ctx.header.button.name,
                      "bg-images": _ctx.header.image
                    }, null, 8, ["title", "subtitle", "description", "secondaryText", "href", "buttonName", "bg-images"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SectionHomeCharacteristics)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "hasBgImage",
                src: _ctx.$getFileSource('../img/bg_usd_coin.bb52fb68.webp')
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_SectionHomeCasino)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_SectionPachinkoCasino)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_SectionHomeGameType)
            ]),
            _createElementVNode("div", {
              class: "casino-box1 container-casinos",
              style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/background/bg_bitcoin_1.webp') +
            ')',
        }),
              role: "img"
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_BitcoinCards)
              ])
            ], 4),
            (!_ctx.sectionLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_SectionWithImage, {
                    class: "special-container",
                    thumbnail: _ctx.section.thumbnail
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Jumbotron, {
                        title: _ctx.section.title,
                        subtitle: _ctx.section.subtitle,
                        description: _ctx.section.description,
                        buttonName: _ctx.section.buttonName,
                        href: 'contact'
                      }, null, 8, ["title", "subtitle", "description", "buttonName"])
                    ]),
                    _: 1
                  }, 8, ["thumbnail"])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.loadingFaq)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_SectionWithImage, {
                    class: "special-container",
                    thumbnail: _ctx.faq.thumbnail,
                    rtl: true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FAQ, {
                        items: _ctx.faq.faqList
                      }, null, 8, ["items"])
                    ]),
                    _: 1
                  }, 8, ["thumbnail"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("img", {
                class: "hasBgImage",
                src: _ctx.$getFileSource('../img/playing_cards_bg.6b30a6bb.webp')
              }, null, 8, _hoisted_13),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_SectionHomeBonuses)
              ])
            ]),
            _createElementVNode("div", {
              class: "container-info",
              style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/background/contact_cover.jpg') +
            ')',
        })
            }, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_SectionHomeInfo, { class: "info-cards" })
              ])
            ], 4),
            (!_ctx.carouselLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_component_carousel_partners, { items: _ctx.getUrl }, null, 8, ["items"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}