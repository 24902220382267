import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17f72c4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex characterList justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_bitcoin_info = _resolveComponent("bitcoin-info")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_SectionHeading, {
            class: "section-heading",
            title: _ctx.cryptoInfo.title,
            subtitle: _ctx.cryptoInfo.subtitle,
            description: _ctx.cryptoInfo.description
          }, null, 8, ["title", "subtitle", "description"])
        ]),
        _createVNode(_component_a_row, {
          justify: "start",
          type: "flex",
          gutter: [20, 30]
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cryptoList, (item) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                xl: 8,
                sm: 12,
                xs: 24,
                key: item
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_bitcoin_info, {
                      thumbnail: item.thumbnail,
                      title: item.title,
                      subtitle: item.description
                    }, null, 8, ["thumbnail", "title", "subtitle"])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}