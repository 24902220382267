import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Menu_Link } from "../../types";
import { ref } from "vue";

export default function () {
  const menu = ref<API_Menu_Link>({} as API_Menu_Link);
  const loading = ref<boolean>(true);

  const fetchMenuLinks = (): Promise<void | API_Menu_Link> => {
    return get<API_Menu_Link>(GET.MENU_LINKS, {
      type: "tree",
    }).then((result) => {
      loading.value = false;
      menu.value = camelizeKeys(result);
    });
  };

  fetchMenuLinks();

  return {
    menu,
    fetchMenuLinks,
    loading,
  };
}
