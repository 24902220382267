
import {defineComponent} from "vue";
import fetchHomeCrypto from "@/misc/composition/fetchHomeCrypto";
import fetchCryptoList from "@/misc/composition/fetchCrypto";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import BitcoinInfo from "@/components/BitcoinInfo/BitcoinInfo.vue";

export default defineComponent({
  name: "Home",
  components: {
    BitcoinInfo,
    SectionHeading,
  },
  setup() {
    const {cryptoInfo, loading} = fetchHomeCrypto();
    const {cryptoList, loading: cryptoListLoading} = fetchCryptoList();
    return {
      cryptoInfo,
      loading,
      cryptoList,
      cryptoListLoading
    };
  },
});
