
import Footer from "./components/Footer/Footer.vue";
import { defineComponent } from "vue";
import MainNav from "./components/MainNav/MainNav.vue";
import fetchMenu from "./misc/composition/fetchMenu";
import fetchMenuLinks from "./misc/composition/fetchMenuLinks";
import LoadingPageAnimVue from "./components/LoadingPageAnim.vue";

export default defineComponent({
  name: "app",
  components: {
    Footer,
    MainNav,
    LoadingPageAnimVue,
  },
  metaInfo : {
    title : ' K8 '
  },
    setup() {
    const { menu, loading } = fetchMenu();
    const { menu: links, loading: linksLoading } = fetchMenuLinks();
    return { menu, links, loading, linksLoading };
  },
});
