import {library as library1} from "@fortawesome/fontawesome-svg-core";
import {
    faFacebook,
    faTwitter,
    faReddit,
    faInstagram,
    faLinkedin,
    faTelegram,
    faPinterest,
    faYoutube,
    faDiscord,
    faTwitch
} from "@fortawesome/free-brands-svg-icons";

library1.add(
    faFacebook,
    faTwitter,
    faReddit,
    faInstagram,
    faLinkedin,
    faTelegram,
    faPinterest,
    faYoutube,
    faDiscord,
    faTwitch
);
