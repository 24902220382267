import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6077140c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardCashBack = _resolveComponent("CardCashBack")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (!_ctx.titleLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_SectionHeading, {
                  class: "section-heading",
                  title: _ctx.titles.section[5].title,
                  subtitle: _ctx.titles.section[5].subtitle,
                  description: _ctx.titles.section[5].description
                }, null, 8, ["title", "subtitle", "description"])
              ]))
            : _createCommentVNode("", true)
        ], true),
        _createVNode(_component_a_row, {
          justify: "center",
          type: "flex",
          gutter: [20, 20]
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bonuses, (bonus) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                xl: 6,
                key: bonus
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CardCashBack, {
                    title: bonus.title,
                    bonus: bonus.bonus,
                    rewards: bonus.rewards,
                    href: bonus.bonusLink
                  }, null, 8, ["title", "bonus", "rewards", "href"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}