import {get} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {Bonus} from "@/types";
import {ref} from "vue";

export default function fetchHomeBonusesByCategory(category: string, limit: number) {
    const bonuses = ref<Bonus>({} as Bonus);
    const loading = ref<boolean>(true);

    const fetchHomeBonusesByCategory = (): Promise<void | Bonus> => {
        return get<Bonus>(GET.BONUSES, {
            "filters[categories][name][$eq]": category,
            "pagination[start]": 0,
            "pagination[limit]": limit
        }).then((result) => {
            loading.value = false;
            bonuses.value = camelizeKeys(result.data);
        });
    };
    fetchHomeBonusesByCategory();
    return {
        bonuses,
        loading,
        fetchHomeBonusesByCategory
    };
}