import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-635a9415"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardGame = _resolveComponent("CardGame")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SectionHeading, {
          class: "section-heading",
          title: _ctx.gameTypeInfo.section.title,
          subtitle: _ctx.gameTypeInfo.section.subtitle,
          description: _ctx.gameTypeInfo.section.description
        }, null, 8, ["title", "subtitle", "description"]),
        _createVNode(_component_a_row, {
          justify: "center",
          type: "flex",
          gutter: [25, 25]
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gameTypeInfo.gameType, (item) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                xl: 8,
                key: item
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CardGame, {
                    class: "card-game",
                    title: item.title,
                    thumbnail: item.image,
                    href: item.link,
                    description: item.description
                  }, null, 8, ["title", "thumbnail", "href", "description"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}