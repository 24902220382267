import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13424628"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "categories-list list-unstyled" }
const _hoisted_3 = { class: "d-flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mobile_submenu = _resolveComponent("mobile-submenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (link, i) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "categories-list p-2",
          key: link
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_router_link, {
              onClick: _withModifiers(($event: any) => (
              link.path.includes('[blank]') === false
                ? _ctx.$emit('menuSelected')
                : _ctx.currentActiveDropdownMenu === i
                ? (_ctx.currentActiveDropdownMenu = -1)
                : (_ctx.currentActiveDropdownMenu = i)
            ), ["prevent"]),
              to: _ctx.removeBlankLink(link.path)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(link.title), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "to"])
          ]),
          (i === _ctx.currentActiveDropdownMenu)
            ? (_openBlock(), _createBlock(_component_mobile_submenu, {
                key: 0,
                items: link.items,
                menuPath: _ctx.replaceBlankLink(link.path),
                onMenuSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menuSelected')))
              }, null, 8, ["items", "menuPath"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}