export enum GET {
  BONUSES = "bonuses",
  HOME_HEADER = "home-header",
  HOME_FAQ = "k8-home-faq",
  HOME_CHARACTERISTICS = "home-characteristic",
  HOME_PARTNERS = "home-partner",
  HOME_GET_IN_TOUCH = "home-get-in-touch",
  HOME_SECTION_WITH_IMAGE = "k8-home-sections-with-image",
  HOME_SECTIONS = "home-section",
  HOME_COMPANY_PROFILE = "k8-home-company",
  CONTACT = "contact",
  FOOTER = "footer",
  MENU = "menu",
  MENU_LINKS = "navigation/render/main-navigation",
  HOME_GAME_TYPE= "k8-home-game-type",
  K8_BLOCKCHAIN_INFO= "k8-block-chain",
  K8_SLOT_INFO= "k8-slots-page",
  K8_LIVE_CASINO_INFO="k8-live-casino",
  K8_GAME_LISTS = "k8-games",
  K8_PACHINKO = "k8-pachinko-page",
  K8_CRYPTO_INFO = "k8-home-crypto",
  K8_CRYPTO = "cryptos",
  K8_PROMOTION = "k8-promotion-page",
  K8_VIP_LEVEL = "vip-levels",
  K8_WALLET_INFO = "k8-wallet-page",
  K8_JAPANESE_DEPOSIT_WITHDRAW = "k8-japan-deposit-withdraw"
}

export enum POST {
  COMMENT = "comments",
}

export enum DELETE {}

export enum PUT {}
