import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image} from "../../types";
import {Link} from "../../types";

type HomeHeader = {
    data: {
        title: string,
        subtitle: string,
        description: string,
        secondarytext: string,
        href: Link,
        image: Image,
        seo: {}
    }
}

type SEO = {
    jsonLd: string,
    metaTitle: string,
    metaDescription: string,
    ogImage: string,
    ogTitle: string,
    ogType: string,
    ogUrl: string,
    seoUid: string,
    twitterCard: string,
    twitterSite: string,
    twitterImage: string,
    twitterTitle: string,
    twitterDescription: string,
}

export default function fetchHomeHeader() {
    const header = ref<HomeHeader>({} as HomeHeader);
    const homeSEO = ref<SEO>({} as SEO);
    const loading = ref<boolean>(true);
    const fetchHeader = () => {
        getSingle<HomeHeader>(GET.HOME_HEADER).then((result) => {
            loading.value = false;
            header.value = camelizeKeys(result.data);
            homeSEO.value = camelizeKeys(result.data.seo);
        });
    };
    fetchHeader();
    return {header, homeSEO, loading, fetchHeader}
}
