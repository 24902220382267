import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e567f1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "categories-list list-unstyled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mobile_submenu = _resolveComponent("mobile-submenu", true)!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("li", { key: item }, [
        _createVNode(_component_router_link, {
          to: item.path.replace(_ctx.menuPath, ''),
          removeDecorations: true,
          class: "d-flex justify-content-between"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menuSelected')))
            }, _toDisplayString(item.title), 1),
            (item.items.length > 0)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  onClick: _withModifiers(($event: any) => (
            _ctx.currentActiveDropdownMenu === i
              ? (_ctx.currentActiveDropdownMenu = -1)
              : (_ctx.currentActiveDropdownMenu = i)
          ), ["prevent"]),
                  icon: ['fas', 'angle-down']
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to"]),
        (i === _ctx.currentActiveDropdownMenu)
          ? (_openBlock(), _createBlock(_component_mobile_submenu, {
              key: 0,
              isNestedSubmenu: true,
              menuPath: _ctx.menuPath,
              items: item.items
            }, null, 8, ["menuPath", "items"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}