import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";


type CryptoInfo = {
    data: {
        title: string;
        subtitle: string;
        description: string;

    }
}

export default function fetchHomeCrypto() {
    const cryptoInfo = ref<CryptoInfo>({} as CryptoInfo);
    const loading = ref<boolean>(true);
    const fetchHomeCrypto = () => {
        return getSingle<CryptoInfo>(GET.K8_CRYPTO_INFO).then((result) => {
            loading.value = false;
            cryptoInfo.value = camelizeKeys(result.data);
        });
    }
    fetchHomeCrypto();
    return {cryptoInfo, loading, fetchHomeCrypto};
}
