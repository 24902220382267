
import { defineComponent } from "vue";
import FooterAboutUs from "../FooterAboutUs/FooterAboutUs.vue";
import fetchFooter from "../../misc/composition/fetchFooter";
export default defineComponent({
  components: { FooterAboutUs, },
  setup() {
    const { footer, loading } = fetchFooter();


    return {
      footer,
      loading,
    };
  },
});
