
import { defineComponent, PropType } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

import "swiper/swiper-bundle.min.css";

// Import Swiper styles
import "swiper/swiper.min.css";
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Object as PropType<String[]>,
    },
  },
});
