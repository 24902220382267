import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82875a98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "desc" }
const _hoisted_2 = { class: "address-social-links" }
const _hoisted_3 = {
  key: 0,
  class: "contact-details"
}
const _hoisted_4 = ["href", "aria-label"]
const _hoisted_5 = {
  key: 1,
  class: "social-links"
}
const _hoisted_6 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("aside", null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.description), 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.contacts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact) => {
              return (_openBlock(), _createElementBlock("p", { key: contact }, [
                _createTextVNode(_toDisplayString(contact.name) + " ", 1),
                _createElementVNode("a", {
                  target: "_blank",
                  href: contact.actionURL,
                  "aria-label": contact.actionURL
                }, [
                  _createElementVNode("strong", null, _toDisplayString(contact.actionMask), 1)
                ], 8, _hoisted_4)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.socials)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socials, (social) => {
              return (_openBlock(), _createElementBlock("li", { key: social }, [
                _createElementVNode("a", {
                  target: "_blank",
                  href: social.link.href,
                  "aria-label": social.link.href
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: [_ctx.convertTextToIconType(social.icon.type), social.icon.name],
                    class: "icon"
                  }, null, 8, ["icon"])
                ], 8, _hoisted_6)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}