
import {
  defineComponent,
  PropType
} from "vue";

import {
  SocialLink,
  ContactLink
} from '@/types'
import convertTextToIconType from "../../misc/helpers/convertTextToIconType";

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    contacts: {
      type: Object as PropType<ContactLink[]>,
      required: false
    },
    socials: {
      type: Object as PropType<SocialLink[]>,
      required: false
    },
  },
  setup() {
    return {convertTextToIconType}
  }
})
