
import {defineComponent} from "vue";
import fetchHomeCompanyProfile from "@/misc/composition/fetchHomeCompanyProfile";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import CardInfo from "@/components/CardInfo/CardInfo.vue";


export default defineComponent({
  name: "Home",
  components: {
    CardInfo,
    SectionHeading,
  },
  setup() {

    const {companyProfile, loading} = fetchHomeCompanyProfile();

    return {companyProfile, loading};
  },
});
