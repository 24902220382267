import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f9e1b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "padding-side" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "section-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_Characteristic = _resolveComponent("Characteristic")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, { loading: _ctx.loading }, null, 8, ["loading"]),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SectionHeading, {
                title: _ctx.characteristic.sectionTitle.title,
                subtitle: _ctx.characteristic.sectionTitle.subtitle,
                description: _ctx.characteristic.sectionTitle.description
              }, null, 8, ["title", "subtitle", "description"])
            ]),
            _createVNode(_component_a_row, {
              justify: "center",
              type: "flex",
              gutter: [24, 24]
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.characteristic.characteristics, (item) => {
                  return (_openBlock(), _createBlock(_component_a_col, {
                    xl: 8,
                    span: 24,
                    key: item
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Characteristic, {
                        id: item.id,
                        title: item.title,
                        subtitle: item.subtitle,
                        description: item.description
                      }, null, 8, ["id", "title", "subtitle", "description"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}