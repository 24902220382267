import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-496dcac3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card anim-shadow-hover position-relative" }
const _hoisted_2 = { class: "img-left overflow-hidden" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content w-100" }
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "desc mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.thumbnail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "game-image",
            src: _ctx.$getFileSource(_ctx.thumbnail.url),
            alt: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description), 1),
        _createVNode(_component_a_button, {
          type: "link",
          class: "button general-btn play-btn",
          href: _ctx.href,
          rel: "nofollow"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'arrow-circle-right'],
              class: "icon"
            }),
            _createTextVNode(" 今すぐプレイ ")
          ]),
          _: 1
        }, 8, ["href"])
      ])
    ])
  ]))
}