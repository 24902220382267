
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    bonus: {
      type: String,
      required: true,
    },
    rewards: {
      type: String,
      required: true,
    },
    category: {
      type: Object
    },
    description: {
      type: String,
      required: false,
    },
    href: {
      type: [String, Object],
      default: "/",
      required: false,
    },
  },
});
