
import {defineComponent} from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import fetchHomeHeader from "../misc/composition/fetchHomeHeader";
import fetchCarouselPartners from "../misc/composition/fetchCarouselPartners";
import fetchSectionWithImage from "../misc/composition/fetchHomSectionWithImage";
import fetchHomeFAQ from "../misc/composition/fetchHomeFAQ";
import MainHeader from "../components/MainHeader/MainHeader.vue";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import CarouselPartners from "../components/CarouselPartners/CarouselPartners.vue";
import SectionHomeCharacteristics from "../components/SectionHomeCharacteristics/SectionHomeCharacteristics.vue";
import SectionHomeCasino from "../components/SectionHomeCasinos/SectionHomeCasino.vue";
import SectionWithImage from "../components/SectionWithImage/SectionWithImage.vue";
import SectionHomeBonuses from "../components/SectionHomeBonuses/SectionHomeBonuses.vue";
import SectionHomeGameType from "@/components/SectionHomeGameType/SectionHomeGameType.vue";
import Jumbotron from "../components/Jumbotron/Jumbotron.vue";
import FAQ from "../components/FAQ/FAQ.vue";
import BitcoinCards from "@/components/BitcoinCards/BitcoinCards.vue";
import SectionHomeInfo from "@/components/SectionHomeInfo/SectionHomeInfo.vue";
import SectionPachinkoCasino from "@/components/SectionPachinkoCasinos/SectionPachinkoCasino.vue"

export default defineComponent({
  name: "Home",
  components: {
    MainHeader,
    CarouselPartners,
    SectionHomeCharacteristics,
    SectionHomeCasino,
    SectionWithImage,
    SectionHomeBonuses,
    SectionHomeGameType,
    Jumbotron,
    FAQ,
    LoadingPageAnim,
    BitcoinCards,
    SectionHomeInfo,
    SectionPachinkoCasino
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "K8オンラインカジノ"
    Object.keys(this.homeSEO).forEach((k, i) => {
      let d = Object.values(this.homeSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{rel: 'canonical', href: 'https://www.k8club.io'}],
      title: title,
      meta: metaTags
    }
  },
  setup() {
    const {header, homeSEO, loading} = fetchHomeHeader();
    const {section, loading: sectionLoading} = fetchSectionWithImage();
    const {carousel, loading: carouselLoading, getUrl,} = fetchCarouselPartners();
    const {faq, loading: loadingFaq} = fetchHomeFAQ();

    document.title = "Home - K8";

    return {
      fetchHomeHeader,
      carousel,
      loading,
      header,
      carouselLoading,
      getUrl,
      section,
      sectionLoading,
      faq,
      loadingFaq,
      homeSEO
    };
  },
});
