// import { notification } from "ant-design-vue";
import { AxiosRequestConfig } from "axios";
import { instance, serialize } from "./helpers";
import qs from "qs"

/** GET / POST */

export async function getSingle<T>(
  endpoint: string,
  entityID?: number,
  config?: AxiosRequestConfig
): Promise<T> {
  try {
    const { data } = await instance.get<T>(`/${endpoint}/${entityID??''}?populate=deep`, config);
    return data;
  } catch (error: any) {
    console.error(error);
    return error;
  }
}

export async function getSingleBySlug<T>(
  endpoint: string,
  slug: string|string[],
  config?: AxiosRequestConfig
): Promise<T> {
  try {
    const { data } = await instance.get<T>(`/${endpoint}/?slug=${slug}`, config);
    return ((data as unknown ) as Array<T>)[0];
  } catch (error: any) {
    console.error(error);
    return error;
  }
}

export async function get<T>(
  endpoint: string,
  payload?: Object,
  config?: AxiosRequestConfig
): Promise<T> {
  const query = qs.stringify(payload);

  try {
    const { data } = await instance.get<T>(`/${endpoint}?${query}&populate=deep`, config);
    return data;
  } catch (error: any) {
    console.error(error);
    return error;
  }
}

export async function post<T>(
  endpoint: string,
  payload: T,
  config?: AxiosRequestConfig
): Promise<any> {
  try {
    return await instance.post<T>(`/${endpoint}`, payload, config);
  } catch (error: any) {
    console.error(error);
    return error;
  }
}
