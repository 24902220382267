import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";


type GameTypeInfo = {
    data: {
        section: {
            title: string;
            subtitle: string;
            description: string;
        },
        gameType: []
    }
}

export default function fetchGameType() {
    const gameTypeInfo = ref<GameTypeInfo>({} as GameTypeInfo);
    const loading = ref<boolean>(true);
    const fetchGameType = () => {
        return getSingle<GameTypeInfo>(GET.HOME_GAME_TYPE).then((result) => {
            loading.value = false;
            gameTypeInfo.value = camelizeKeys(result.data);
        });
    }
    fetchGameType();
    return {gameTypeInfo, loading, fetchGameType};
}
