
import { defineComponent } from "vue";
import CardCasinoOffer from "../../components/CardCasinoOffer/CardCasinoOffer.vue";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import fetchK8GameList from "@/misc/composition/fetchK8GameList";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";

export default defineComponent({
  name: "SectionHomeCasino",
  components: {
    CardCasinoOffer,
    SectionHeading,
  },
  setup() {
    const { k8GameList, loading } = fetchK8GameList({gameType: 'slot'});
    const { titles, loading: titleLoading } = fetchHomeTitles();
    return {
      loading,
      titleLoading,
      titles,
      k8GameList,
    };
  },
});
