import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Thumbnail} from "@/types";


type Crypto = {
    data: {
        title: string;
        description: string;
        thumbnail: Thumbnail;

    }
}

export default function fetchCryptoList() {
    const cryptoList = ref<Crypto>({} as Crypto);
    const loading = ref<boolean>(true);
    const fetchCryptoList = () => {
        return getSingle<Crypto>(GET.K8_CRYPTO).then((result) => {
            loading.value = false;
            cryptoList.value = camelizeKeys(result.data);
        });
    };
    fetchCryptoList();
    return {cryptoList, loading, fetchCryptoList};
}
