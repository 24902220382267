import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowCircleRight,
  faStar,
  faGlobe,
  faPlus,
  faMinus,
  faMinusCircle,
  faPlusCircle,
  faArrowRight,
  faCheckCircle,
  faHeart,
  faTimesCircle,
  faShareAlt,
  faStarHalfAlt,
  faTag,
  faLaptop,
  faHourglassHalf,
  faEuroSign,
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
  faLongArrowAltRight,
  faInbox,
  faInfo,
  faArrowAltCircleRight,
  faViruses,
  faCalendar,
  faBars,
  faAngleDown,
  faCheck
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowCircleRight,
  faArrowAltCircleRight,
  faStar,
  faBars,
  faGlobe,
  faPlus,
  faArrowRight,
  faMinus,
  faMinusCircle,
  faPlusCircle,
  faCheckCircle,
  faHeart,
  faAngleDown,
  faTimesCircle,
  faShareAlt,
  faStarHalfAlt,
  faTag,
  faLaptop,
  faHourglassHalf,
  faEuroSign,
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
  faLongArrowAltRight,
  faInbox,
  faInfo,
  faViruses,
  faCalendar,
  faCheck
);
