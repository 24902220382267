import { getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Menu } from "@/types";
import { ref } from "vue";

export default function () {
  const menu = ref<API_Menu>({} as API_Menu);
  const loading = ref<boolean>(true);

  const fetchMenu = (): Promise<void | API_Menu> => {
    return getSingle<API_Menu>(GET.MENU).then((result) => {
      loading.value = false;
      menu.value = camelizeKeys(result.data);
    });
  };

  fetchMenu();

  return {
    menu,
    fetchMenu,
    loading,
  };
}
