import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/live-casino",
    name: "LiveCasino",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "live-casino" */ "../views/LiveCasino.vue"),
  },
  {
    path: "/slot-casino",
    name: "SlotCasino",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "live-casino" */ "../views/SlotCasino.vue"),
  },
  {
    path: "/pachinko",
    name: "Pachinko",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "live-casino" */ "../views/Pachinko.vue"),
  },
  {
    path: "/blockchain",
    name: "Blockchain",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "live-casino" */ "../views/Blockchain.vue"),
  },
  {
    path: "/promotion",
    name: "Promotion",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "live-casino" */ "../views/Promotion.vue"),
  },
  {
    path: "/wallet-info",
    name: "WalletInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "live-casino" */ "../views/Wallet.vue"),
  },
  {
    path: "/japanese-bank-deposit-withdraw",
    name: "JapaneseFiat",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "live-casino" */ "../views/JapaneseFiat.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    // redirect: "/",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PATH),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
