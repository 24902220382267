
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    /** USES V-HTML !! */
    description: {
      type: String,
      required: true
    },
  }
})
