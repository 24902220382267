import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image} from "../../types";
import {Link} from "../../types";

type HomeCharacteristic = {
    data: {
        href: Link,
        image: Image,
        sectionTitle: {
            title: String;
            subtitle: String;
            description: String;
        }
    };
}

export default function fetchHomeCharacteristics() {
    const characteristic = ref<HomeCharacteristic>({} as HomeCharacteristic);
    const loading = ref<boolean>(true);
    const fetchCharacteristic = () => {
        getSingle<HomeCharacteristic>(GET.HOME_CHARACTERISTICS).then((result) => {
            loading.value = false;
            characteristic.value = camelizeKeys(result.data);
        });
    };
    fetchCharacteristic();
    return {characteristic, loading, fetchCharacteristic}
}
