import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98e38ac6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card d-flex anim-shadow-hover anim-translateY-hover" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "post-name-listings" }
const _hoisted_6 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.thumbnail)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.thumbnail)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.$getFileSource(_ctx.thumbnail.url),
                alt: ""
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1),
      _createVNode(_component_a_button, {
        type: "link",
        class: "button general-btn",
        href: _ctx.href
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'arrow-circle-right'],
            class: "icon"
          }),
          _createTextVNode(" 今すぐプレイ ")
        ]),
        _: 1
      }, 8, ["href"])
    ])
  ]))
}