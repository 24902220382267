import { camelCase } from "lodash";
import axios from "axios";

export const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/",
  timeout: 55000,
  headers: {
    "Content-type": "application/json",
  },
});

/** UTILITY FUNCTION */

export function serialize(obj: any | object) {
  const str: string[] = [];
  for (const p in obj)
    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
  return str.join("&");
}

export function camelizeKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
}

export function camelCaseToWords(str: string): string {
  const matchedString = str.match(/^[a-z]+|[A-Z][a-z]*/g);
  if (matchedString) {
    return matchedString
      .map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase();
      })
      .join(" ");
  }
  return str;
}

export function kebabize(str: string): string {
  return str
    .split("")
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
}

export function kebabizeColon(str: string): string {
  return str
      .split("")
      .map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? ":" : ""}${letter.toLowerCase()}`
            : letter;
      })
      .join("");
}