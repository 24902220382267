import {get, getSingle} from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Footer } from "@/types";
import { ref } from "vue";


export default function () {
  const footer = ref<API_Footer>({} as API_Footer);
  const loading = ref<boolean>(true);
  
  const fetchFooter = () : Promise<void | API_Footer> => {
    return getSingle<API_Footer>(GET.FOOTER).then((result) => {
      loading.value = false;
      footer.value = camelizeKeys(result.data);
    });
  };

  fetchFooter();

  return {
    footer,
    fetchFooter,
    loading,
  };
}
