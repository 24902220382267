
import { defineComponent } from "vue";
import { API_Menu_Link } from "../types";
import MobileSubmenu from "./MobileSubmenu.vue";

export default defineComponent({
  components: { MobileSubmenu },
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      currentActiveDropdownMenu: -1,
      expanded: false,
    };
  },
  methods: {
    isMegaMenu(item: API_Menu_Link) {
      return item.items
        .map((item) => item.items.length > 0)
        .every((v) => v === true);
    },
  },
  setup() {
    function removeBlankLink(link: string): string {
      if (link.includes("[blank]")) {
        return "";
      }
      return link;
    }
    function replaceBlankLink(link: string): string {
      if (link.includes("[blank]")) {
        return link;
      }
      return "";
    }
    return { removeBlankLink, replaceBlankLink };
  },
});
