export default {
  install: (app: any) => {
    app.config.globalProperties.$getFileSource = (obj: string) => {
      
      if (obj.includes("https")) { // external
        return obj;
      } else if(obj.startsWith("/uploads/") || obj.includes("flags")) { // strapi
        return process.env.VUE_APP_IMG_URL + obj.toLowerCase() + '?auto=format';
      } else {
        try {
          return require("../../assets/" + obj); // internal
        } catch (err) {
          return process.env.VUE_APP_BUILD_PATH ?? '/' + obj; // in public directory
        }
      }
    };
  },
};
