import {get} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";

type API_TITLE = {
    data: {
        title: string,
        subtitle: string,
        description: string

    }
}

export default function fetchHomeSectionTitles() {
    const titles = ref<API_TITLE>({} as API_TITLE);
    const loading = ref<boolean>(true);

    const fetchTitles = (): Promise<void | API_TITLE> => {
        return get<API_TITLE>(GET.HOME_SECTIONS, {}).then((result) => {
            loading.value = false;
            titles.value = camelizeKeys(result.data);
        });
    };
    fetchTitles();
    return {
        titles,
        fetchTitles,
        loading
    };
}