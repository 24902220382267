
import {defineComponent} from "vue";
import CardGame from "../../components/CardGame/CardGame.vue";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import fetchGameType from "@/misc/composition/fetchGameType";

export default defineComponent({
  name: "Home",
  components: {
    CardGame,
    SectionHeading,
  },
  setup() {
    const {titles, loading} = fetchHomeTitles();
    const {gameTypeInfo, loading: gameTypeLoading} = fetchGameType();
    return {
      loading,
      titles,
      gameTypeInfo,
      gameTypeLoading
    };
  },
});
