import {get} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image} from "@/types";

type K8GameList = {
    data: {
        k_8_game_type: {
            gameTypeName: string;
        },
        game: {
            gameName: string,
            gameId: string,
            gameLink: string,
            gameImage: Image,
        }
    }
}

export default function fetchK8GameList({gameType}: { gameType: string }) {
    const k8GameList = ref<K8GameList>({} as K8GameList);
    const loading = ref<boolean>(true);
    const fetchK8GameList = () => {
        get<K8GameList>(GET.K8_GAME_LISTS, {'filters[categories][slug][$eq]': gameType}).then((result) => {
            loading.value = false;
            k8GameList.value = camelizeKeys(result.data)
        });
    };
    fetchK8GameList();
    return {k8GameList, loading, fetchK8GameList}
}
