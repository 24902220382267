import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_main_nav = _resolveComponent("main-nav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_a_back_top = _resolveComponent("a-back-top")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, {
      loading: _ctx.loading && _ctx.linksLoading
    }, null, 8, ["loading"]),
    _createElementVNode("div", null, [
      (!_ctx.loading && !_ctx.linksLoading)
        ? (_openBlock(), _createBlock(_component_main_nav, {
            key: 0,
            siteName: _ctx.menu.siteName,
            logo: _ctx.menu.logo.url,
            links: _ctx.links,
            socials: _ctx.menu.socials
          }, null, 8, ["siteName", "logo", "links", "socials"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, { class: "overflow-hidden" }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: _ctx.$route.fullPath
              }))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }),
      _createVNode(_component_Footer)
    ]),
    _createVNode(_component_a_back_top, { class: "back-to-top" })
  ], 64))
}