import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";

type API_HOME_COMPANY_PROFILE = {
    data: {
        title: string,
        description: string
        subtitle: string
        companyProfile: {}
    }
}

export default function fetchHomeCompanyProfile() {
    const companyProfile = ref<API_HOME_COMPANY_PROFILE>({} as API_HOME_COMPANY_PROFILE);
    const loading = ref<boolean>(true);

    const fetchHomeCompanyProfile = () => {
        getSingle<API_HOME_COMPANY_PROFILE>(GET.HOME_COMPANY_PROFILE).then((result) => {
            loading.value = false;
            companyProfile.value = camelizeKeys(result.data);
        });
    };
    fetchHomeCompanyProfile();
    return {companyProfile, loading, fetchHomeCompanyProfile}
}
