
import { defineComponent } from "vue";
import CardCashBack from "../../components/CardCashBack/CardCashBack.vue";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import fetchHomeBonusesByCategory from "@/misc/composition/fetchHomeBonusByCategories";

export default defineComponent({
  name: "Home",
  components: {
    CardCashBack,
    SectionHeading,
  },
  setup() {
    const { bonuses, loading } = fetchHomeBonusesByCategory('Long Term Bonuses', 4);
    const { titles, loading: titleLoading } = fetchHomeTitles();

    return {
      bonuses,
      loading,
      titleLoading,
      titles
    };
  },
});
